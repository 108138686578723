import React, { useState, useEffect } from "react";

export default function ConnectDispositionCodes() {
  useEffect(() => {
    setCCP();
  }, []);

  const setCCP = () => {
    connect.agentApp.initApp(
      "customviews",
      "customviews-container",
      "https://cloudinteract1.my.connect.aws/connect" + "/stargate/app",
      { style: "width:100%; height:100%;" }
    );
  };

  return <div id="customviews-container"></div>;
}
