import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ConnectCustomerProfiles from "./components/connectCustomerProfiles";
import ConnectWisdom from "./components/connectWisdom";
import ConnectDispositionCodes from "./components/connectDispoitionCodes";
import { styled } from "@mui/material/styles";
import OutboundActivity from "./components/outboundActivity";
import InboundActivity from "./components/inboundActivity";
import TransferToTeams from "./components/transferToTeams";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import VideoChat from "./components/videoChat";
import RealtimeTranscript from "./components/realtimeTranscript";
import CallInformation from "./components/callInformation";
import NonCallWork from "./components/nonCallWork";
import AgentEvaluations from "./components/agentEvaluations";
import Kigali from "./components/kigali";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, height: "100%", paddingTop: 1 }}>
          <Typography style={{ height: "100%" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MainContent(props) {
  const [value, setValue] = React.useState(0);

  const [contact, setContact] = useState(null);
  const [agent, setAgent] = useState(null);
  const [stateARN, setStateArn] = useState(null);
  const [stateStartTimestamp, setStateStartTimestamp] = useState(null);
  const [stateTimecode, setStateTimecode] = useState(null);
  const [agentState, setAgentState] = useState(null);
  const [agentID, setAgentID] = useState(null);
  const [showNonCallWorkTab, setShowNonCallWorkTab] = useState(false);

  const setCCP = () => {
    connect.contact((contact) => {
      contact.onConnected(async () => {
        setContact(contact);
      });
    });

    connect.agent((agent) => {
      setAgent(agent);

      agent.onStateChange(function (change) {
        var state = agent.getState();

        let arn = agent.toSnapshot().agentData.configuration.agentARN;
        setAgentID(arn);

        if (change.newState === "Non phone work") {
          setStateArn(state.agentStateARN);
          setStateStartTimestamp(state.startTimestamp.toLocaleString());
          setAgentState(state);
          setStateTimecode(state.startTimestamp.getTime());
          setShowNonCallWorkTab(true);
          setValue(7);
        }
        if (change.newState === "Available") {
          setStateArn(null);
          setStateStartTimestamp(null);
          setValue(0);
          setShowNonCallWorkTab(false);
        }
      });
    });
  };

  useEffect(() => {
    setCCP();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#b5f602",
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontFamily: "GT Ultra Median",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      color: "rgba(38, 27, 27, 1.0)",
      "&.Mui-selected": {
        color: "#261B1B",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );

  return (
    <>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Grid container sx={{ width: "100%", height: "100%" }}>
          <Grid item xs={9}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <StyledTab label="Call" {...a11yProps(0)} />
                <StyledTab label="My Service Requests" {...a11yProps(1)} />
                <StyledTab label="Teams Transfer" {...a11yProps(2)} />

                <StyledTab label="Video" {...a11yProps(3)} />
                <StyledTab label="Real Time" {...a11yProps(4)} />
                <StyledTab label="My Evaluations" {...a11yProps(5)} />
                
                {showNonCallWorkTab && (
                  <StyledTab label="Non Call Work" {...a11yProps(7)} />
                )}
              </StyledTabs>
            </Box>

            <CustomTabPanel value={value} index={0} style={{ height: "100%" }}>
              <InboundActivity contact={contact} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1} style={{ height: "100%" }}>
              <OutboundActivity agent={agent} token={props.token} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2} style={{ height: "100%" }}>
              <TransferToTeams contact={contact} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3} style={{ height: "100%" }}>
              <VideoChat contact={contact} />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={4} style={{ height: "100%" }}>
              <RealtimeTranscript contact={contact} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5} style={{ height: "100%" }}>
              <AgentEvaluations agent={agent} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6} style={{ height: "100%" }}>
              <Kigali />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={7} style={{ height: "100%" }}>
              <NonCallWork
                agentId={agentID}
                contact={contact}
                token={props.token}
                stateArn={stateARN}
                stateStartTimestamp={stateStartTimestamp}
                stateTimecode={stateTimecode}
                state={agentState}
              />
            </CustomTabPanel>
          </Grid>
          <Grid item xs={3}>
            <ConnectWisdom />
          </Grid>
        </Grid>
      </Box>
      <pre>agent-portal-light</pre>
    </>
  );
}
