import React, { useState, useEffect } from "react";
import {
  getSummaryFromContactId,
  getTranscriptForContactID,
} from "../services/gatewayApi";
import useInterval from "../useInterval";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import ListItemText from "@mui/material/ListItemText";

import Typography from "@mui/material/Typography";
import {
  Card,
  CardHeader,
  Alert,
  AlertTitle,
  Button,
  ButtonGroup,
  CardContent,
  Tooltip,
  Container,
  Paper,
} from "@mui/material";
//import SupportAgent from "@mui/icons-material/SupportAgent";
//import Person from "@mui/icons-material/Person";

export default function RealtimeTranscript(props) {
  const [contactId, setContactId] = useState(null);
  const [transcript, setTranscript] = useState(null);
  const [summary, setSummary] = useState(null);
  const pagingTimeout = 2000;

  useEffect(() => {
    if (props.contact == null) return;
    setContactId(props.contact.getContactId());
    getTranscriptForContactID(props.contact.getContactId()).then((data) => {
      setTranscript(data);
    });

    props.contact.onRefresh((contact) => {
      setContactId(contact.getContactId());
    });
  }, [props.contact]);

  useInterval(() => {
    if (contactId) {
      updateTranscript();
    }
  }, pagingTimeout);

  const updateTranscript = async () => {
    getTranscriptForContactID(contactId).then((data) => {
      setTranscript(data);
    });
  };

  const GenerateSummary = async () => {
    getSummaryFromContactId(contactId).then((data) => {
      setSummary(data);
    });
  };

  const CopyToClipboard = () => {
    navigator.clipboard.writeText(summary);
  };

  const SentimentToColor = (sentiment) => {
    if (sentiment == "POSITIVE") {
      return { marginRight: "10px", color: "green" };
    }
    if (sentiment == "NEGATIVE") {
      return { marginRight: "10px", color: "red" };
    } else {
      return { marginRight: "10px", color: "black" };
    }
  };

  const ConvertSentimentToColour = (sentiment) => {
    if (sentiment == "POSITIVE") {
      return "green";
    }
    if (sentiment == "NEGATIVE") {
      return "red";
    } else {
      return "black";
    }
  };

  return (
    <>
      {transcript != null ? (
        <Paper elevation={3}>
          <Card variant="outlined">
            <CardHeader title="Summary"></CardHeader>
            <CardContent>
              {summary ? (
                <Typography variant="body2" color="text.secondary">
                  <Paper>{summary}</Paper>
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                    sx={{ marginTop: "10px" }}
                  >
                    <Button
                      onClick={() => {
                        CopyToClipboard();
                      }}
                    >
                      Copy to Clipboard
                    </Button>
                    <Button
                      onClick={() => {
                        GenerateSummary();
                      }}
                    >
                      Regenerate Summary
                    </Button>
                  </ButtonGroup>
                  <div sx={{ marginTop: "10px" }}>
                    <i>
                      Summaries are generated from as much of the captured
                      transcript is present. Carefully review transcripts for
                      quality before using.
                    </i>
                  </div>
                </Typography>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => {
                    GenerateSummary();
                  }}
                >
                  Generate Summary
                </Button>
              )}
            </CardContent>
          </Card>

          <Container sx={{ maxHeight: "50vh", overflow: "scroll" }}>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {transcript.map((t) => (
                <ListItem>
                  {/* <Tooltip title={t.Role + " (" + t.Sentiment + ")"}>
                    {t.Role == "CUSTOMER" ? (
                      <Person sx={SentimentToColor(t.Sentiment)} />
                    ) : (
                      <SupportAgent sx={SentimentToColor(t.Sentiment)} />
                    )}
                  </Tooltip> */}
                  {/* <Tooltip title={t.Sentiment}>
                    {t.Sentiment == "POSITIVE" ? (
                      <SentimentSatisfiedAlt />
                    ) : t.Sentiment == "NEGATIVE" ? (
                      <SentimentVeryDissatisfiedIcon />
                    ) : (
                      <SentimentNeutral />
                    )}
                  </Tooltip> */}

                  <ListItemText
                    primaryTypographyProps={{
                      style: { color: ConvertSentimentToColour(t.Sentiment) },
                    }}
                    primary={t.Content}
                  />
                </ListItem>
              ))}
            </List>
          </Container>
        </Paper>
      ) : (
        <Alert severity="info">
          <AlertTitle>No active contact</AlertTitle>
          When you are on a call, the transcription information will appear
          here.
        </Alert>
      )}
    </>
  );
}
