export default function Kigali(props) {
  return (
    <>
      <iframe
        src="https://dev.kigali.cloudinteract.io/Connect/Home"
        width={"100%"}
        height={"95%"}
        style={{ border: "none" }}
      />
    </>
  );
}
