import axios from "axios";

async function createMeeting() {
  const response = await axios.get(
    "https://ci-awsconnect-webchat.azurewebsites.net/api/CreateWebchatMeeting?code=VIP6WTrHNugrrT4s22t41YNtbK6fJSWMw_aKaYO9AY_yAzFuza_6nw=="
  );
  return response.data;
}

async function shortenUrl(url) {
  const config = {
    headers: {
      Authorization: `Bearer lb9A9w6XlKxim8NxE62CFkT5oUZb3D3jgOUdXeaIwpAakmo4O7hMtQLbSizA`,
    },
  };

  const data = {
    url: url,
  };

  const response = await axios.post(
    "https://api.tinyurl.com/create",
    data,
    config
  );
  return response.data.data.tiny_url;
}

export { createMeeting, shortenUrl };
