import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Providers } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";

import App from "./App";

Providers.globalProvider = new Msal2Provider({
  clientId: "10e2ad4f-e1ae-4224-aa3a-49ef3edafc03",
  scopes: [
    "user.read",
    "people.read",
    "User.ReadBasic.All",
    "people.read.all",
    "Presence.Read.All",
  ],
});

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  rootElement
);
