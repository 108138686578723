import React, { useState, useEffect } from "react";
import { ConnectState } from "./Models/ConnectState";
import "amazon-connect-streams";
import "amazon-connect-chatjs";

export default function AmazonConnect({ onStateSet }) {
  let connectState = new ConnectState();

  useEffect(() => {
    setCCP();
  }, []);

  const setCCP = () => {
    // connect.agentApp.initApp(
    //   "ccp",
    //   "ccp",
    //   "https://cloudinteract1.my.connect.aws/connect/ccp-v2",
    //   { style: "width:400px; height:600px;" }
    // );

    connect.core.initCCP(document.getElementById("ccp"), {
      ccpUrl: "https://cloudinteract1.my.connect.aws/connect/ccp-v2",
      region: "us-east-1",
      loginPopup: true,
      loginOptions: {
        autoClose: true,
        height: 600,
        width: 800,
        top: 50,
        left: 50,
      },
      loginPopupAutoClose: true,
      softphone: {
        allowFramedSoftphone: true,
        disableRingtone: false,
      },
      pageOptions: {
        enableAudioDeviceSettings: true,
        enablePhoneTypeSettings: true,
      },
      shouldAddNamespaceToLogs: true,
    });

    connect.ChatSession.setGlobalConfig({
      loggerConfig: {
        // optional, the logging configuration. If omitted, no logging occurs
        // You can provide your own logger here, otherwise this property is optional
        customizedLogger: {
          debug: (...msg) => console.debug(...msg), // REQUIRED, can be any function
          info: (...msg) => console.info(...msg), // REQUIRED, can be any function
          warn: (...msg) => console.warn(...msg), // REQUIRED, can be any function
          error: (...msg) => console.error(...msg), // REQUIRED, can be any function
        },
        // There are five levels available - DEBUG, INFO, WARN, ERROR, ADVANCED_LOG. Default is INFO
        level: connect.LogLevel.INFO,
        // Choose if you want to use the default logger
        useDefaultLogger: true,
      },
      region: "us-east-1", // optional, defaults to: "us-west-2"
      //Control switch for enabling/disabling message-receipts (Read/Delivered) for messages
      //message receipts use sendEvent API for sending Read/Delivered events https://docs.aws.amazon.com/connect-participant/latest/APIReference/API_SendEvent.html
      features: {
        messageReceipts: {
          shouldSendMessageReceipts: true, // DEFAULT: true, set to false to disable Read/Delivered receipts
          throttleTime: 5000, //default throttle time - time to wait before sending Read/Delivered receipt.
        },
      },
    });

    connect.core.onInitialized(() => {
      connectState.isLoaded = true;
      onStateSet(connectState);
    });

    connect.agent((agent) => {
      // gather information about the agent
      connectState.agentName = agent.getName();
      const agentContacts = agent.getContacts();
      const agentPermissions = agent.getPermissions();
      onStateSet(connectState);
    });

    connect.contact((contact) => {
      // receive contact metadata
      const contactAttributes = contact.getAttributes();
    });

    connect.contact((contact) => {
      // alert("contact");

      contact.onRefresh((contact) => {
        // alert("hello");

        // if (contactAttributes["DefaultFlowForAgentUI"]) {
        //   const contactflowId =
        //     contactAttributes["DefaultFlowForAgentUI"].value;
        //   alert(contactflowId);
        //   const customViewsIframe = document.querySelector(
        //     "#customviews-container > iframe"
        //   );

        //   customViewsIframe.setAttribute(
        //     "src",
        //     `https://cloudinteract1.my.connect.aws/connect/stargate/app?contactFlowId=${contactflowId}&currentContactId=${currentContactId}`
        //   );
        // }
        const customViewsIframe = document.querySelector(
          "#customviews-container > iframe"
        );
        var currentUrl = customViewsIframe.getAttribute("src");
        if (currentUrl.indexOf("contactFlowId") == -1) {
          const currentContactId = contact.contactId;
          const contactAttributes = contact.getAttributes();
          console.log(contactAttributes);

          customViewsIframe.setAttribute(
            "src",
            `https://cloudinteract1.my.connect.aws/connect/stargate/app?contactFlowId=0cfc9d41-92a8-4f74-9ffb-6c167c949022&currentContactId=${currentContactId}`
          );
        }
      });
    });
  };

  return <></>;
}
