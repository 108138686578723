import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableRow,
  TableCell,
  Grid,
} from "@mui/material";
import { CardContent } from "@material-ui/core";
import evalData from "../services/sampleEvaluation.json";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { amber, green, red } from "@mui/material/colors";
import { PersonCard } from "@microsoft/mgt-react";

export default function AgentEvaluations(props) {
  const [selectedEval, setSelectedEval] = useState(null);
  const [selectedQuestions, setSelectedQuestions] = useState(null);

  const handleEvalClick = (event, id) => {
    setSelectedEval(id);
    setSelectedQuestions(evalData.find((e) => e.id == id));
  };
  const isEvalSelected = (id) => selectedEval == id;

  const questionAnswerToIcon = (score) => {
    if (score > 1)
      return (
        <SentimentSatisfiedAltIcon
          fontSize="large"
          sx={{ verticalAlign: "middle", color: green[500] }}
        />
      );
    if (score > 0)
      return (
        <SentimentNeutralIcon
          fontSize="large"
          sx={{ verticalAlign: "middle", color: amber[500] }}
        />
      );
    return (
      <SentimentVeryDissatisfiedIcon
        fontSize="large"
        sx={{ verticalAlign: "middle", color: red[500] }}
      />
    );
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" margin="10px">
          <TableHead>
            <TableRow>
              <TableCell>Evaluation Date</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Evaluator</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {evalData.map((row) => (
              <TableRow
                hover
                role="checkbox"
                onClick={(event) => handleEvalClick(event, row.id)}
                selected={isEvalSelected(row.id)}
                key={row.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {row.datetime}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.title}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.score}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.evaluator}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedEval && (
        <Container sx={{ marginTop: "3em" }}>
          <Paper elevation={3}>
            <Typography variant="h4" gutterBottom>
              {evalData.find((e) => e.id == selectedEval).title}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Evaluation Date:{" "}
                  {evalData.find((e) => e.id == selectedEval).datetime} <br />
                  Overall Score:{" "}
                  {evalData.find((e) => e.id == selectedEval).score}
                  <br />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                Evaluated By:{" "}
                {evalData.find((e) => e.id == selectedEval).evaluator}{" "}
                <PersonCard
                  personQuery={
                    evalData.find((e) => e.id == selectedEval).evaluatorEmail
                  }
                  showPresence={true}
                />
              </Grid>
            </Grid>

            {selectedQuestions &&
              selectedQuestions.sections.map((section) => (
                <>
                  <hr />
                  <Typography variant="h6">{section.sectionTitle}</Typography>
                  Overall Score: {section.sectionScore}
                  <ul>
                    {section.questions.map((question) => (
                      <>
                        <li>
                          {question.questionTitle} :
                          {questionAnswerToIcon(question.answer)}
                        </li>
                      </>
                    ))}
                  </ul>
                </>
              ))}
          </Paper>
        </Container>
      )}
    </>
  );
}
