import axios from "axios";

async function getTranscriptForContactID(contactID, startMS) {
  const response = await axios.get(
    "https://sr1w1in5n8.execute-api.us-east-1.amazonaws.com/live?contactID=" +
      contactID
  );
  return response.data;
}

async function getSummaryFromContactId(contactID) {
  const response = await axios.post(
    "https://sr1w1in5n8.execute-api.us-east-1.amazonaws.com/live?contactID=" +
      contactID
  );
  return response.data;
}

async function setContactAttribute(contactID, attribute, value, token) {
  let requestData = {
    contact_id: contactID,
    attribute: attribute,
    value: value,
  };
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.put(
    "https://sr1w1in5n8.execute-api.us-east-1.amazonaws.com/live/contactattributes",
    requestData,
    config
  );
  return response.data;
}

async function getOutboundCliNumbers(token, agentId) {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.get(
    "https://sr1w1in5n8.execute-api.us-east-1.amazonaws.com/live/outboundcli?agent_id=" +
      agentId,
    config
  );
  return response.data;
}

async function setOutboundCliNumber(token, agentId, cli) {
  console.log("Setting outbound CLI to " + cli + " for agent " + agentId + ".");
  let requestData = {
    agent_id: agentId,
    outbound_cli: cli,
  };
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.post(
    "https://sr1w1in5n8.execute-api.us-east-1.amazonaws.com/live/outboundcli",
    requestData,
    config
  );
  return response.data;
}

async function setAgentNonWorkDetail(token, agentId, timecode, data) {
  let requestData = {
    agent_id: agentId,
    timecode: timecode,
    data: data,
  };
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.put(
    "https://sr1w1in5n8.execute-api.us-east-1.amazonaws.com/live/agent-nonworkdetail",
    requestData,
    config
  );
  return response.data;
}

async function getRoutingProfileQueueStats(token, routingProfileId) {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const response = await axios.get(
    "https://sr1w1in5n8.execute-api.us-east-1.amazonaws.com/live/queuestats?profile_id=" +
      routingProfileId,
    config
  );

  return response.data;
}

export {
  getTranscriptForContactID,
  getSummaryFromContactId,
  setContactAttribute,
  getOutboundCliNumbers,
  setOutboundCliNumber,
  setAgentNonWorkDetail,
  getRoutingProfileQueueStats,
};
