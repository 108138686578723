import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Alert,
  AlertTitle,
} from "@mui/material";
import { PeoplePicker, Person } from "@microsoft/mgt-react";

export default function TransferToTeams(props) {
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const [people, setPeople] = useState([]);
  let stopSendingMax = 100;
  let stopSendingCount = 0;

  const openTeamsChat = () => {
    let url = "https://teams.microsoft.com/l/chat/0/0?users=";
    people.forEach((person) => {
      url += person.userPrincipalName + ",";
    });
    url = url.substring(0, url.length - 1);
    url = url + "&topicName=Swarm%20Chat";
    window.open(url, "_blank");
  };

  const sendDDTMFConstantly = (connection, dtmfTone) => {
    console.log("Sending DTMF:" + dtmfTone);
    connection.sendDigits(dtmfTone);
    if (stopSendingCount < stopSendingMax) {
      sleep(2000).then(() => {
        sendDDTMFConstantly(connection, dtmfTone);
        stopSendingCount++;
      });
    }
  };

  const transferToUser = (userId) => {
    connect.agent((agent) => {
      var newEndpoint = connect.Endpoint.byPhoneNumber("+442045768746");
      stopSendingCount = 0;
      // newEndpoint.name = userId;
      //newEndpoint.userId = userId;
      //newEndpoint.endpointId = userId;
      //newEndpoint.phoneNumber = "+442045768746;ext=12345";
      //console.log(newEndpoint.phoneNumber);

      //Surround the userID with hashtags so that it's more easier identified by the back-end service
      userId = "#" + userId + "#";

      var contact = agent.getContacts(connect.ContactType.VOICE)[0];

      contact.onEnded((contact) => {
        stopSendingCount = stopSendingMax;
      });

      contact.addConnection(newEndpoint, {
        success: function (anything) {
          console.log("Transfer OK");

          var initialConnectionId =
            contact.getActiveInitialConnection().connectionId;
          var allConnections = contact.getConnections();
          var newConnection = allConnections.find((connection) => {
            return connection.connectionID != initialConnectionId;
          });

          let waitForCall = true;
          while (waitForCall) {
            console.log("Status of call is: " + newConnection.getState().type);
            if (newConnection.isConnected()) {
              console.log("Sending DTMF:" + userId);
              waitForCall = false;

              sleep(2000).then(() => {
                console.log("DTMF wake");
                sendDDTMFConstantly(newConnection, userId);
              });
            } else {
              console.log("Waiting for call");
            }
          }
        },
        failure: function (err) {
          console.log("Transfer failed");
          console.log(err);
        },
      });
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {props.contact != null ? (
            <Card variant="outlined">
              <CardHeader title="Key Contacts"></CardHeader>

              <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Hover for more details, click to consult.
                </Typography>
                <List>
                  <ListItem>
                    <Person
                      userId="0935765b-6c79-4e29-a705-41371e9de671"
                      showPresence={true}
                      view={3}
                      avatarSize="large"
                      personCardInteraction={1}
                      onClick={
                        props.contact
                          ? () => {
                              transferToUser("12345");
                            }
                          : undefined
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <Person
                        userId="064464ee-b088-47a1-8f79-c29adc2876d6" //nick
                        showPresence={true}
                        view={3}
                        avatarSize="large"
                        personCardInteraction={1}
                        onClick={
                          props.contact
                            ? () => {
                                transferToUser("54321");
                              }
                            : undefined
                        }
                      />
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <Person
                      userId="e72d574f-0001-4779-8438-ae563d080979" //dave
                      showPresence={true}
                      view={3}
                      avatarSize="large"
                      personCardInteraction={1}
                      onClick={
                        props.contact
                          ? () => {
                              transferToUser("23451");
                            }
                          : undefined
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <Person
                      userId="49ec4c25-78c5-4532-9af5-9654f9c2b3ef" //alex
                      showPresence={true}
                      view={3}
                      avatarSize="large"
                      personCardInteraction={1}
                      onClick={
                        props.contact
                          ? () => {
                              transferToUser("34512");
                            }
                          : undefined
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <Person
                      userId="36ec7db4-df7f-4158-9b45-78f24d64fb8e" //bentley
                      showPresence={true}
                      view={3}
                      avatarSize="large"
                      personCardInteraction={1}
                      onClick={
                        props.contact
                          ? () => {
                              transferToUser("45123");
                            }
                          : undefined
                      }
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          ) : (
            <Alert severity="info">
              <AlertTitle>No active contact</AlertTitle>
              When you are on a call, transfer information will appear here.
            </Alert>
          )}
        </Grid>
        <Grid item xs={6}>
          <Card variant="outlined">
            <CardHeader title="Swarm Chat"></CardHeader>
            <CardContent>
              <PeoplePicker
                selectionChanged={(people) => {
                  setPeople(people.detail);
                }}
              />

              {people != null && people.length > 0 && (
                <Box marginTop={"10px"}>
                  <Button
                    onClick={() => {
                      openTeamsChat();
                    }}
                    variant="contained"
                  >
                    Start Swarm Chat with users
                  </Button>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
