import React, { useState, useEffect } from "react";
import {
  Select,
  Box,
  MenuItem,
  Button,
  Paper,
  FormControl,
  InputLabel,
  CardContent,
  Card,
  CardHeader,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  TextField,
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { getOutboundCliNumbers } from "../services/gatewayApi";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  setOutboundCliNumber,
  getSelectedCliNumber,
} from "../services/gatewayApi";

export default function OutboundActivity(props) {
  useEffect(async () => {
    await setCCP();
  }, []);

  const [agentId, setAgentId] = useState(null);
  const [agentName, setAgentName] = useState(null);
  const [agentArn, setAgentArn] = useState(null);
  const [outboundNumbers, setOutboundNumbers] = useState([]);
  const [chosenOutboundNumber, setChosenOutboundNumber] = useState("");
  const [selectedSR, setSelectedSR] = useState(null);
  const [expanded, setExpanded] = React.useState("panel1");

  const setCCP = async () => {
    console.log(props.agent.toSnapshot());
    let arn = props.agent.toSnapshot().agentData.configuration.agentARN;
    setAgentArn(arn);
    setAgentName(props.agent.getName());
    setAgentId(arn.substring(arn.lastIndexOf("/") + 1));
    await getOutboundNumbers(arn.substring(arn.lastIndexOf("/") + 1));
  };

  const getOutboundNumbers = async (agentId) => {
    let response = await getOutboundCliNumbers(props.token, agentId);
    console.log(response);
    if (response.status === 200) {
      setOutboundNumbers(response.outbound_brand_config);
      const selected = response.outbound_brand_config.filter(
        ({ selected }) => selected === true
      );
      if (selected) setChosenOutboundNumber(selected[0].phone_number);
    }
  };

  const handleOutboundNumberChange = (event) => {
    setChosenOutboundNumber(event.target.value);
    setOutboundCliNumber(props.token, agentId, event.target.value);
  };

  const handleServiceRequestClick = (event, srnum) => {
    setSelectedSR(srnum);
  };

  const handleClaimSRClick = (event, srnum) => {
    let copy = serviceRequests;
    copy.find((sr) => sr.srnum == srnum).owner = agentName;
    setServiceRequests(copy);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOutboundCallClick = () => {
    let number = additionalInfo.find(
      (sr) => sr.srnum == selectedSR
    ).payernumber;
    let endpoint = connect.Endpoint.byPhoneNumber(number);
    connect.agent((agent) => {
      agent.connect(endpoint);
    });
  };

  const isServiceRequestSelected = (srnum) => selectedSR == srnum;
  const [serviceRequests, setServiceRequests] = useState([
    {
      srnum: "BFB-FJAFWJFA",
      date: new Date().toLocaleString(),
      summary: "Primary Policy ID: 12345",
      owner: "",
      status: "New",
      subsource: "SF-BV Basic Only",
      patientPayerName: "United Healthcare",
      patientPlanName: "PPO Medicare Advantage Plan",
    },
    {
      srnum: "BWW-NWJCBAJW",
      date: new Date().toLocaleString(),
      summary: "Primary Policy ID: 154321",
      owner: "",
      status: "New",
      subsource: "SF-BV Basic Only",
      patientPayerName: "United Healthcare",
      patientPlanName: "PPO Medicare Advantage Plan",
    },
  ]);

  const additionalInfo = [
    {
      srnum: "BFB-FJAFWJFA",
      providername: "Allison Chambers",
      providernumber: "7482165398",
      payername: "United Healthcare",
      payernumber: "+447812986876",
      planname: "PPO Medicare Advantage Plan",
      groupnumber: "62981",
      effectivedate: "3/15/2021",
    },
    {
      srnum: "BWW-NWJCBAJW",
      providername: "Ethan Mitchell",
      providernumber: "9254038672",
      payername: "United Healthcare",
      payernumber: "+447746909590",
      planname: "Molina Healthcare Plus Plan",
      groupnumber: "37459",
      effectivedate: "11/28/2021",
    },
  ];

  const previousActivity = [
    {
      statusDate: "11/15/2022 06:21:58",
      program: "Medicare Advantage",
      product: "Medicare Advantage",
      businessGroup: "Neurology",
      srnum: "BFB-FJAFWJFA",
      summary:
        "04AFJWSAOF3HFH\nProvider Status: In Network\nReferral Required: No\nIndividual DED/Amount Met:0\nJ0585 B&B Coinsurance: 0%\nJ0585 B&B Copay: $0.00",
      srtype: "Insurance Verification",
      srsubtype: "External Patient Responsibility",
      srstatus: "Closed",
      ownedBy: "ANFWADMIN",
      closedDate: "11/15/2022 06:21:58",
    },
    {
      statusDate: "11/15/2021 06:21:58",
      program: "Medicare Advantage",
      product: "Medicare Advantage",
      businessGroup: "Neurology",
      srnum: "BFB-FJAFWJFA",
      summary:
        "04AFJWSAOF3HFH\nProvider Status: In Network\nReferral Required: No\nIndividual DED/Amount Met:0\nJ0585 B&B Coinsurance: 0%\nJ0585 B&B Copay: $0.00",
      srtype: "Insurance Verification",
      srsubtype: "External Patient Responsibility",
      srstatus: "Closed",
      ownedBy: "ANFWADMIN",
      closedDate: "11/15/2021 06:21:58",
    },
    {
      statusDate: "11/15/2020 06:21:58",
      program: "Medicare Advantage",
      product: "Medicare Advantage",
      businessGroup: "Neurology",
      srnum: "BFB-FJAFWJFA",
      summary:
        "04AFJWSAOF3HFH\nProvider Status: In Network\nReferral Required: No\nIndividual DED/Amount Met:0\nJ0585 B&B Coinsurance: 0%\nJ0585 B&B Copay: $0.00",
      srtype: "Insurance Verification",
      srsubtype: "External Patient Responsibility",
      srstatus: "Closed",
      ownedBy: "ANFWADMIN",
      closedDate: "11/15/2020 06:21:58",
    },
  ];

  return (
    <>
      {props.agent && (
        <>
          <Card variant="outlined">
            <CardHeader title="My Service Requests"></CardHeader>
            <CardContent>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                  margin="10px"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>SR #</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>Summary</TableCell>
                      <TableCell>Owner</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Sub Source</TableCell>
                      <TableCell>Patient Payer Name</TableCell>
                      <TableCell>Patient Plan Name</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {serviceRequests.map((row) => (
                      <TableRow
                        hover
                        role="checkbox"
                        onClick={(event) =>
                          handleServiceRequestClick(event, row.srnum)
                        }
                        selected={isServiceRequestSelected(row.srnum)}
                        key={row.srnum}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.srnum}
                        </TableCell>
                        <TableCell>{row.date}</TableCell>
                        <TableCell>{row.summary}</TableCell>
                        <TableCell>{row.owner}</TableCell>
                        <TableCell>{row.status}</TableCell>
                        <TableCell>{row.subsource}</TableCell>
                        <TableCell>{row.patientPayerName}</TableCell>
                        <TableCell>{row.patientPlanName}</TableCell>
                        <TableCell>
                          <Button
                            disabled={row.owner != ""}
                            size="small"
                            onClick={(event) =>
                              handleClaimSRClick(event, row.srnum)
                            }
                          >
                            Claim
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {selectedSR && (
                <>
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Provider &amp; Payer Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack direction="row" spacing={2}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="standard-required"
                          label="Provider Name"
                          value={
                            additionalInfo.find((sr) => sr.srnum == selectedSR)
                              .providername
                          }
                          size="small"
                        />

                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="standard-required"
                          label="Plan Name"
                          value={
                            additionalInfo.find((sr) => sr.srnum == selectedSR)
                              .planname
                          }
                          size="small"
                        />
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="standard-required"
                          label="Group #"
                          value={
                            additionalInfo.find((sr) => sr.srnum == selectedSR)
                              .groupnumber
                          }
                          size="small"
                        />
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="standard-required"
                          label="Effective Date"
                          value={
                            additionalInfo.find((sr) => sr.srnum == selectedSR)
                              .effectivedate
                          }
                          size="small"
                        />
                      </Stack>
                      <Stack marginTop={"1em"} direction="row" spacing={2}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="standard-required"
                          label="Payer Name"
                          value={
                            additionalInfo.find((sr) => sr.srnum == selectedSR)
                              .payername
                          }
                          size="small"
                        />

                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="standard-required"
                          label="Payer Phone #"
                          value={
                            additionalInfo.find((sr) => sr.srnum == selectedSR)
                              .payernumber
                          }
                          size="small"
                        />

                        <FormControl>
                          <InputLabel id="demo-simple-select-label">
                            Outbound #
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={chosenOutboundNumber}
                            label="Outbound #"
                            onChange={handleOutboundNumberChange}
                            size="small"
                          >
                            {Array.from(outboundNumbers).map((row) => (
                              <MenuItem
                                key={row.phone_number}
                                value={row.phone_number}
                              >
                                {row.brand_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Button onClick={(e) => handleOutboundCallClick()}>
                          Call Payer
                        </Button>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Previous Activity</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          size="small"
                          aria-label="a dense table"
                          margin="10px"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Status Date</TableCell>
                              <TableCell>Program</TableCell>
                              <TableCell>Product</TableCell>
                              <TableCell>Business Group</TableCell>
                              <TableCell>SR #</TableCell>
                              <TableCell>SR Type</TableCell>
                              <TableCell>SR Sub Type</TableCell>
                              <TableCell>SR Status</TableCell>
                              <TableCell>Owned By</TableCell>
                              <TableCell>Closed</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {previousActivity.map((row) => (
                              <TableRow
                                hover
                                role="checkbox"
                                onClick={(event) =>
                                  handlePreviousActivityClick(event, row.srnum)
                                }
                                key={row.srnum}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.srnum}
                                </TableCell>
                                <TableCell>{row.statusDate}</TableCell>
                                <TableCell>{row.program}</TableCell>
                                <TableCell>{row.product}</TableCell>
                                <TableCell>{row.businessGroup}</TableCell>
                                <TableCell>{row.srtype}</TableCell>
                                <TableCell>{row.srsubtype}</TableCell>
                                <TableCell>{row.srstatus}</TableCell>
                                <TableCell>{row.ownedBy}</TableCell>
                                <TableCell>{row.closedDate}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Create New Activity</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack direction="row" spacing={2}>
                        <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                          <InputLabel id="demo-simple-select-label">
                            Call Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Business Value"
                          >
                            <MenuItem value={"Request for Service"}>
                              Request for Service
                            </MenuItem>
                            <MenuItem value={"Call Payer to Obtain Info"}>
                              Call Payer to Obtain Info
                            </MenuItem>
                            <MenuItem value={"Relay Results to Requestor"}>
                              Relay Results to Requestor
                            </MenuItem>
                            <MenuItem value={"Record IV Data"}>
                              Record IV Data
                            </MenuItem>
                            <MenuItem value={"Insurance Verification"}>
                              Insurance Verification
                            </MenuItem>
                          </Select>
                        </FormControl>

                        <TextField label="Summary" size="small"></TextField>

                        <TextField
                          label="Remote Agent Name"
                          size="small"
                        ></TextField>
                        <TextField
                          label="Remote Agent Ref"
                          size="small"
                        ></TextField>
                        <Button>Save</Button>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
}
