import React, { useState, useEffect } from "react";
import { createMeeting, shortenUrl } from "../services/acsApi";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import {
  createAzureCommunicationCallAdapter,
  fromFlatCommunicationIdentifier,
  CallComposite,
} from "@azure/communication-react";
import { Alert, AlertTitle, Button } from "@mui/material";

export default function VideoChat(props) {
  const [agentChatSession, setAgentChatSession] = useState(null);
  const [isConnectionBroken, setIsConnectionBroken] = useState(false);

  const [agentJoinWebUrl, setAgentJoinWebUrl] = useState("");
  const [callAdapter, setCallAdapter] = useState();
  const [userId, setUserId] = useState("");
  const [meetingId, setMeetingId] = useState("");
  const [token, setToken] = useState("");
  const [credential, setCredential] = useState(null);

  useEffect(() => {
    setupChatContact().catch((err) => {
      alert(err.message);
    });
  }, []);

  const setupChatContact = async () => {
    if (!props.contact) {
      setAgentChatSession(null);
      return;
    }
    if (props.contact && props.contact.getType() !== connect.ContactType.CHAT) {
      return;
    }
    try {
      //props.contact.onConnected(async () => {
        const cnn = props.contact
          .getConnections()
          .find((c) => c.getType() === connect.ConnectionType.AGENT);

    if (!cnn) {
      return;
    }
    setIsConnectionBroken(false);
    const agentChatSession = connect.ChatSession.create({
      chatDetails: cnn.getMediaInfo(), // REQUIRED
      options: {
        region: "us-east-1", // REQUIRED, must match the value provided to `connect.core.initCCP()`
      },
      type: connect.ChatSession.SessionTypes.AGENT, // REQUIRED
      websocketManager: connect.core.getWebSocketManager(), // REQUIRED
    });

        console.log(agentChatSession);
        const { connectCalled, connectSuccess } =
          await agentChatSession.connect();
        setAgentChatSession(agentChatSession);
     // });
    } catch (err) {
      alert(err.message);
      console.log(err);
    }
  };

  const onVideoClick = async () => {
    var meeting = await createMeeting();
    var shortUrl = await shortenUrl(meeting.customerJoinUrl);

    setMeetingId(meeting.groupId);
    setUserId(meeting.agentAcsUserId);

    let localToken = meeting.agentAcsToken;
    setToken(localToken);

    let localCredential = new AzureCommunicationTokenCredential(localToken);

    console.log(localCredential);
    setCredential(localCredential);

    if (localCredential !== undefined) {
      const createAdapter = async (credential) => {
        var displayName = "Agent";

        var adapter = await createAzureCommunicationCallAdapter({
          userId: fromFlatCommunicationIdentifier(meeting.agentAcsUserId),
          displayName,
          credential,
          locator: { groupId: meeting.groupId },
        });
        setCallAdapter(adapter);
        
      };
      createAdapter(localCredential).catch((err) => {
        alert(err.message);
      });
    } else {
      alert("credential is undefined");
    }

    if (meeting.teamsJoinUrl) {
      setAgentJoinWebUrl(meeting.teamsJoinUrl);
    } else {
      setAgentJoinWebUrl(meeting.agentJoinUrl);
    }
    var userMessage =
      "Your advisor would like to start a video call. Please join the call here: " +
      shortUrl;
    await sendMessage(userMessage);
  };

  const sendMessage = async (message) => {
    await agentChatSession.sendMessage({
      contentType: "text/plain",
      message: message,
    });
  };

  return (
    <>
      {agentChatSession != null && !isConnectionBroken ? (
        <>
          <div
            style={{
              height: "100%",
              position: "relative",
            }}
          >
            <div
              style={{
                margin: 0,
                position: "absolute",
                top: "50%",
                left: "50%",
                msTransform: "translate(-50%, -50%)",
                transform: "translate(-50%, -50%)",
              }}
            >

              {!callAdapter && (              
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={(p) => {
                  onVideoClick();
                }}
              >
                Escalate to video
              </Button>
              )}
          

          {callAdapter && (
            <div>
              <div style={{ height: "425px", width: "100%" }}>
                <CallComposite key={meetingId} adapter={callAdapter} />
              </div>
              <p>
                You can also <a href={agentJoinWebUrl}>join in a new window.</a>
              </p>
            </div>
          )}

            </div>
          </div>
        </>
      ) : (
        <Alert severity="info">
          <AlertTitle>No active contact</AlertTitle>
          When you are in a chat conversation, video escalation options will be
          shown here.
        </Alert>
      )}
    </>
  );
}
