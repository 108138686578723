import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Alert,
  AlertTitle,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { CardContent } from "@material-ui/core";
import { setAgentNonWorkDetail } from "../services/gatewayApi";

export default function NonCallWork(props) {
  const [reasonCode, setReasonCode] = React.useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");

  const handleReasonCodeChange = (event) => {
    setReasonCode(event.target.value);
  };

  const handleAdditionalInformationChange = (event) => {
    setAdditionalInfo(event.target.value);
  };

  const handleNonWorkDetailsSave = () => {
    let data = {
      agentID: props.agentId,
      reasonCode: reasonCode,
      additionalInfo: additionalInfo,
      agentState: props.state,
    };

    setAgentNonWorkDetail(
      props.token,
      props.agentId,
      props.stateTimecode,
      data
    );
  };

  return (
    <Card variant="outlined">
      <CardHeader title="Non Call Work"></CardHeader>
      <CardContent>
        <Box sx={{ minWidth: 120 }}>
          <Typography variant="button" display="block" gutterBottom>
            Started: {props.stateStartTimestamp}
          </Typography>
        </Box>

        <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Reason Code</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={reasonCode}
              label="Reason Code"
              onChange={handleReasonCodeChange}
            >
              <MenuItem value={"Administration"}>Administration</MenuItem>
              <MenuItem value={"Data Gathering"}>Data Gathering</MenuItem>
              <MenuItem value={"Provider Research"}>Provider Research</MenuItem>
              <MenuItem value={"Meeting"}>Meeting</MenuItem>
              <MenuItem value={"Manager Approved Other"}>
                Manager Approved Other
              </MenuItem>
              <MenuItem value={"FMLA"}>FMLA</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-static"
              label="Additional Information"
              multiline
              rows={4}
              value={additionalInfo}
              onChange={handleAdditionalInformationChange}
            />
          </FormControl>
        </Box>
        <Button
          variant="contained"
          fullWidth={true}
          onClick={handleNonWorkDetailsSave}
        >
          Update Non Work Details
        </Button>
      </CardContent>
    </Card>
  );
}
