import React, { useState, useEffect, Fragment } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import Skeleton from "@mui/material/Skeleton";
import { createTheme } from "@material-ui/core/styles";
import MainContent from "./MainContent";
import Header from "./Header";
import { Amplify, Auth, Hub } from "aws-amplify";
import FederatedSignIn from "./FederatedSignin";

import "./styles.css";

import AmazonConnect from "./AmazonConnect";
import { SettingsInputComponent } from "@material-ui/icons";
import { ConnectState } from "./Models/ConnectState";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#F9F5F1",
    color: "rgba(38, 27, 27, 1.0)",
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export default function App() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [connectState, setConnectState] = useState(new ConnectState());
  const federatedIdName = "CIAgentPortal";
  const [token, setToken] = useState(null);

  Amplify.configure({
    Auth: {
      region: "us-east-1",
      userPoolId: "us-east-1_H2HUjMQAL",
      userPoolWebClientId: "5h8tdsvd5vju3pi24pu17ob8q4",
      oauth: {
        domain: "ciagentportal.auth.us-east-1.amazoncognito.com",
        scope: ["email", "openid"],
        redirectSignIn: "https://portal.cloudinteract.io",
        //redirectSignIn: "https://localhost:3000",
        redirectSignOut: "https://portal.cloudinteract.io",
        responseType: "code",
      },
    },
  });

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          setToken("grating...");
          getToken().then((userToken) => setToken(userToken.idToken.jwtToken));
          break;
        case "signOut":
          setToken(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          break;
      }
    });
  }, []);

  function getToken() {
    return Auth.currentSession()
      .then((session) => session)
      .catch((err) => console.log(err));
  }

  const onStateSet = (theState) => {
    setConnectState(theState);
    console.log(theState);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#b5f602",
      },
    },
  });

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };
  return (
    <Fragment>
      {token ? (
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <CssBaseline />
            {connectState && connectState.isLoaded && (
              <Header
                handleDrawerToggle={handleDrawerToggle}
                toggleDarkMode={toggleDarkMode}
                darkMode={darkMode}
              />
            )}
            {connectState == null ||
              (!connectState.isLoaded && <Skeleton variant="rectangular" />)}

            <main
              className={clsx(classes.content, {
                [classes.contentShift]: open,
              })}
            >
              <div className={classes.drawerHeader} />
              <div className="ContentContainer">
                <div className="LeftContentContainer">
                  <div
                    id="ccp"
                    style={{ width: "100%", height: "100%", border: 0 }}
                  ></div>
                </div>

                <div className="RightContentContainer">
                  {connectState && connectState.isLoaded && (
                    <MainContent connectState={connectState} token={token} />
                  )}
                  {connectState == null ||
                    (!connectState.isLoaded && (
                      <Skeleton variant="rectangular" />
                    ))}
                </div>
              </div>
            </main>
          </div>

          <AmazonConnect onStateSet={onStateSet} />
        </ThemeProvider>
      ) : (
        <FederatedSignIn federatedIdName={federatedIdName} />
      )}
    </Fragment>
  );
}
