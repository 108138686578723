import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import logo from "./images/CI-Logo_large_for_dark_background.png";
import { Login } from "@microsoft/mgt-react";
import QueueInfo from "./components/queueInfo";

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: "#261B1B",
    minHeight: "80px",
    color: theme.palette.text.primary,
    [theme.breakpoints.up("sm")]: {
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  rightIcons: {
    marginLeft: theme.spacing(0.5),
  },
  spacer: {
    flexGrow: 1,
  },
}));

export default function Header({
  handleDrawerToggle,
  toggleDarkMode,
  darkMode,
}) {
  const classes = useStyles();
  return (
    <AppBar position="fixed" className={classes.appbar}>
      <Toolbar>
        <div className="TitleContainer">
          <img className="logo" src={logo} alt="Fortrea Logo" />
          <span className="Title" noWrap>
            Agent Portal
          </span>
        </div>
        <div className={classes.spacer} />

        {/* <QueueInfo /> */}

        {/* <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className={classes.rightIcons}
        >
          <Badge badgeContent={4} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton> */}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className={classes.rightIcons}
        >
          <Login className="login" />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
