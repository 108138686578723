import React, { useState, useEffect } from "react";

export default function ConnectWisdom() {
  useEffect(() => {
    setCCP();
  }, []);

  const setCCP = () => {
    connect.agentApp.initApp(
      "wisdom",
      "wisdom-container",
      "https://cloudinteract1.my.connect.aws/connect" +
        "/wisdom-v2?mode=embedded&theme=integ_01",
      { style: "width:100%; height:100%;border:0" }
    );
  };

  return <div id="wisdom-container"></div>;
}
