import React, { useState, useEffect } from "react";
import CallInformation from "./callInformation";
import ConnectDispositionCodes from "./connectDispoitionCodes";
import {
  Card,
  CardHeader,
  Alert,
  AlertTitle,
  Button,
  ButtonGroup,
  Box,
} from "@mui/material";
import { Call } from "@material-ui/icons";

export default function InboundActivity(props) {
  const [contactAttributes, setContactAttributes] = useState(null);
  const [contactLinks, setContactLinks] = useState(null);
  useEffect(() => {
    if (props.contact == null) return;
    props.contact.onDestroy(() => {
      clearValues();
    });

    updateAttributes();
  }, [props.contact]);

  const clearValues = () => {
    setContactAttributes(null);
    setContactLinks(null);
  };

  const updateAttributes = () => {
    try {
      let keys = Object.entries(props.contact.getAttributes());
      let attributeArray = [];
      let linksArray = [];
      keys.forEach((element) => {
        if (element[1].name.startsWith("CRM_")) {
          attributeArray.push({
            name: element[1].name.substring(4).replace("_", " "),
            value: element[1].value,
          });
        }
        if (element[1].name.startsWith("LINK")) {
          linksArray.push({
            name: element[1].name.substring(5).replace("_", " "),
            value: element[1].value,
          });
        }
      });
      setContactAttributes(attributeArray);
      setContactLinks(linksArray);
    } catch (e) {
      console.log("Error getting attributes");
      setContactAttributes(null);
      setContactLinks;
      null;
    }
  };

  return (
    <>
      {contactAttributes != null || contactLinks != null ? (
        <>
          <Card variant="outlined">
            <CardHeader title="Contact Information"></CardHeader>
            <Box sx={{ margin: "10px" }}>
              {contactAttributes != null && (
                <ul>
                  {contactAttributes.map((attribute) => (
                    <li>
                      {attribute.name} - {attribute.value}
                    </li>
                  ))}
                </ul>
              )}

              {contactLinks != null && (
                <ButtonGroup
                  orientation="vertical"
                  aria-label="vertical outlined button group"
                >
                  {contactLinks.map((link) => (
                    <Button key="link.name" href={link.value} target="_blank">
                      {link.name}
                    </Button>
                  ))}
                </ButtonGroup>
              )}
            </Box>
          </Card>
          {/* <CallInformation contact={props.contact} token={props.token} /> */}
          <ConnectDispositionCodes />
        </>
      ) : (
        <Alert severity="info">
          <AlertTitle>No active contact</AlertTitle>
          When you are on a call, the contact information will appear here.
        </Alert>
      )}
    </>
  );
}
